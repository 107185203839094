import * as React from "react";
const SvgTripod = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "tripod.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 12.992789, "inkscape:cy": 10.88357, "inkscape:window-width": 1920, "inkscape:window-height": 1043, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("g", { id: "g3", transform: "matrix(0.04253123,0,0,0.04253123,-49.001951,-1.5217275)" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-8-6", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1344.2161,326.17917 v 17.9002 a 7,3 0 0 0 6.9996,2.99982 7,3 0 0 0 7.0001,-2.99982 v -17.9002 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-8-3", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1344.2156,121.17917 v 204.99989 a 7,3 0 0 0 7.0001,3.00033 7,3 0 0 0 7.0001,-3.00033 V 121.17917 Z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-23-4-1", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.8,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 1387.403,102.43834 a 7,1.5 30 0 0 -0.6599,0.23358 l -10.0005,17.32039 12.1244,7.00008 10.0004,-17.32089 a 7,1.5 30 0 0 -5.3123,-4.79869 7,1.5 30 0 0 -6.1521,-2.43447 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-0-7-3", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1377.4023,119.75912 a 7,1.5 30 0 0 -0.6594,0.23306 7,1.5 30 0 0 -6e-4,-2e-5 7,1.5 30 0 0 -7e-4,0.002 l -129.9993,225.1651 12.1243,7.00008 130.0004,-225.16661 -6e-4,-3e-5 a 7,1.5 30 0 0 -5.3117,-4.79915 7,1.5 30 0 0 -6.1526,-2.43397 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-8", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1351.2157,100.27967 a 7,3 0 0 0 -6.9996,2.99981 v 17.89607 a 7,3 0 0 0 -5e-4,0.004 7,3 0 0 0 7.0001,2.99981 7,3 0 0 0 7.0001,-2.99981 v -17.89969 a 7,3 0 0 0 -7.0001,-2.99981 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-23-4-7-8", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1247.4029,344.92572 a 7,1.5 30 0 0 -0.6599,0.23306 l -9.9999,17.32039 a 7,1.5 30 0 0 5.3124,4.79919 7,1.5 30 0 0 6.8119,2.2009 l 9.9999,-17.32039 a 7,1.5 30 0 0 -5.3123,-4.79868 7,1.5 30 0 0 -6.1521,-2.43447 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-23-4-5-6", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1315.0291,102.43834 a 1.5,7 60 0 0 -6.1521,2.43448 1.5,7 60 0 0 -5.3123,4.79867 l 10.0004,17.32091 12.1243,-7.00009 -10.0004,-17.32039 a 1.5,7 60 0 0 -0.6599,-0.23358 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-0-7-8-9", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1325.0297,119.75912 a 1.5,7 60 0 0 -6.1526,2.43391 1.5,7 60 0 0 -5.3118,4.79922 l 129.9998,225.16664 12.1243,-7.00008 -129.9998,-225.16665 a 1.5,7 60 0 0 -0.6599,-0.23304 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-23-4-5-9-4", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1455.0295,344.92572 a 1.5,7 60 0 0 -6.1526,2.43396 1.5,7 60 0 0 -5.3123,4.79919 1.5,7 60 0 0 5e-4,0 1.5,7 60 0 0 5e-4,0.001 l 9.9994,17.31936 a 1.5,7 60 0 0 6.812,-2.2009 1.5,7 60 0 0 5.3123,-4.79919 l -9.9999,-17.32039 a 1.5,7 60 0 0 -0.6599,-0.23306 z" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.8,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path63-5-3", cx: 1259.2904, cy: -304.45511, rx: 7, ry: 1.5, transform: "rotate(30)" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.8,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path63-8-3", cx: -1081.0844, cy: 1046.7609, rx: 7, ry: 1.5, transform: "matrix(-0.8660254,0.5,0.5,0.8660254,0,0)" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.8,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path64", cx: 1351.2159, cy: 103.27941, rx: 7, ry: 3 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1351.2159,127.53881 c -0.1613,5.75125 0.01,14.92252 0,19.36708", id: "path80" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1351.2159,209.25876 c -1.6416,5.80792 -0.3013,15.62671 0,20.18921", id: "path81" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1350.2819,243.52684 c 2.8284,23.30034 0.9591,49.12211 0.934,71.48441", id: "path82" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1258.3034,346.66105 c 7.9555,-24.6961 25.6667,-46.91975 38.9185,-69.46162", id: "path83" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1297.3359,263.69574 c 13.8547,-23.04403 31.1154,-44.37891 38.6914,-68.90062", id: "path84" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1378.3609,133.0355 c -5.1474,7.95998 -14.8887,21.34232 -17.7437,25.61091", id: "path85" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1323.4449,129.44035 c 2.2274,9.6942 8.2674,19.20949 12.7088,26.73653", id: "path86" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1336.1537,156.17688 c 6.9903,4.17756 12.9845,22.66628 14.1282,25.22856", id: "path87" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1360.5072,191.85936 c 5.1517,11.90959 14.462,29.37248 17.9648,37.59732", id: "path88" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1396.9821,251.26846 c 1.4291,5.92676 5.178,11.39595 8.1581,16.40155", id: "path89" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1429.2566,311.917 c 3.8772,8.7631 5.9782,14.14147 9.429,21.26138", id: "path90" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1399.3421,267.73769 c 9.1648,10.52677 14.8635,21.7229 21.1851,32.26231", id: "path91" }))));
export default SvgTripod;
