import * as React from "react";
const SvgBipod = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "bipod.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 12.992789, "inkscape:cy": 10.88357, "inkscape:window-width": 1920, "inkscape:window-height": 1043, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("g", { id: "g2", transform: "matrix(0.04252895,0,0,0.04252895,-30.98601,-1.6531247)" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-23-4", style: {
  fill: "#7933ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.8,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 963.85952,104.33988 a 7,1.5 30 0 0 -0.65991,0.23358 l -10.00043,17.32039 12.12433,7.00009 10.00042,-17.3209 a 7,1.5 30 0 0 -5.31234,-4.79868 7,1.5 30 0 0 -6.15207,-2.43448 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-0-7", style: {
  fill: "#a05a2c",
  fillOpacity: 0.998109,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 953.85909,121.66079 a 7,1.5 30 0 0 -0.65991,0.23306 l -129.99981,225.16662 12.12433,7.00009 129.99981,-225.16662 a 7,1.5 30 0 0 -5.31234,-4.79919 7,1.5 30 0 0 -6.15208,-2.43396 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-23-4-7", style: {
  fill: "#7933ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 823.85927,346.82741 a 7,1.5 30 0 0 -0.6599,0.23306 l -9.99991,17.32039 a 7,1.5 30 0 0 5.31233,4.79919 7,1.5 30 0 0 6.812,2.2009 l 9.99991,-17.32039 a 7,1.5 30 0 0 -5.31235,-4.79919 7,1.5 30 0 0 -6.15208,-2.43396 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-23-4-5-9", style: {
  fill: "#7933ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1032.1458,347.06047 -12.1243,7.00009 9.9999,17.32039 a 1.5,7 60 0 0 6.812,-2.2009 1.5,7 60 0 0 5.3123,-4.79919 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-0-7-8", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 902.14597,121.89385 -12.12433,7.00009 129.99986,225.16662 a 1.5,7 60 0 0 6.8119,-2.2009 1.5,7 60 0 0 5.3124,-4.79919 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-23-4-5", style: {
  fill: "#7933ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 891.48564,104.33988 a 1.5,7 60 0 0 -6.15209,2.43448 1.5,7 60 0 0 -5.31232,4.79868 l 10.00041,17.3209 a 1.5,7 60 0 0 6.81199,-2.2009 1.5,7 60 0 0 5.31234,-4.79919 l -10.00042,-17.32039 a 1.5,7 60 0 0 -0.65991,-0.23358 z" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.8,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path63-5", cx: 893.44177, cy: -91.036591, rx: 7, ry: 1.5, transform: "rotate(30)" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.8,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path63-8", cx: -713.33429, cy: 536.63599, rx: 7, ry: 1.5, transform: "matrix(-0.8660254,0.5,0.5,0.8660254,0,0)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 900.09669,139.39962 c 13.42734,11.86012 24.93245,32.18303 34.99596,47.74248", id: "path70" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 935.87291,194.87439 c 1.29185,1.72597 3.13936,4.88743 3.84225,5.98171", id: "path71" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 952.65276,222.96862 c 6.88165,12.082 15.646,24.47819 23.11678,35.35114", id: "path72" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 989.79163,287.1457 c 0.73464,4.06151 3.95028,6.94129 5.87756,10.32783", id: "path73" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1002.1992,312.36901 c 3.9803,7.60574 9.1613,13.78469 13.7813,20.73616", id: "path74" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 939.82645,158.58508 c 4.36073,-8.33491 8.59337,-17.07788 12.82631,-25.49011", id: "path75" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 831.51659,344.61251 c 1.72312,-7.59447 9.637,-17.68313 12.35498,-21.86948", id: "path77" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 854.05975,310.75413 c 6.7025,-14.28195 17.81484,-27.75929 25.51586,-41.06619", id: "path78" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 885.94585,249.99065 c 5.14448,-11.52545 13.61974,-23.28625 19.55699,-33.41174", id: "path79" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "M 989.79161,287.1457 C 985.4322,275.63855 977.78849,269.25034 970.87009,261.08759", id: "path92" }))));
export default SvgBipod;
