import * as React from "react";
const SvgPole = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "pole.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 12.992789, "inkscape:cy": 10.967939, "inkscape:window-width": 2560, "inkscape:window-height": 1376, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("g", { id: "g4", transform: "matrix(0.04140661,-0.02390613,0.02390613,0.04140661,-35.475007,50.729055)" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1230.9236,-170.89399 a 7,0.5 0 0 1 -0.051,-0.0601 l -3e-4,-20.00003 14.0004,-1.4e-4 -3e-4,20.00028 a 7,0.5 0 0 1 -7,0.49967 7,0.5 0 0 1 -6.9491,-0.43967 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1230.9237,-190.8938 a 7,0.5 0 0 1 -0.051,-0.0603 l 4e-4,-229.99989 13.9997,1.2e-4 3e-4,229.99963 a 7,0.5 0 0 1 -7.0001,0.50013 7,0.5 0 0 1 -6.9492,-0.43966 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1230.9236,-420.89391 a 7,0.5 0 0 1 -0.051,-0.0601 l -3e-4,-20.00003 a 7,0.5 0 0 1 7.0001,-0.50013 7,0.5 0 0 1 7.0003,0.5 l -3e-4,20.00028 a 7,0.5 0 0 1 -7,0.49966 7,0.5 0 0 1 -6.9491,-0.43967 z" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path109-7-6", cx: 1237.8728, cy: 440.95413, rx: 7, ry: 0.5, transform: "scale(1,-1)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1232.5411,-202.15542 c 7.7452,-5.16178 8.733,-14.04982 10.2407,-21.04108", id: "path111-3" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1238.877,-414.78423 c 0.6302,9.08751 1.5201,18.51767 1.233,26.96432", id: "path121-3" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1236.1707,-227.68562 c 1.2406,-4.75271 1.2208,-11.11768 1.3866,-15.06019", id: "path128-4" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1237.6895,-250.39867 c 2.1429,-15.63036 -0.72,-34.26404 0.1833,-48.46391", id: "path129-3" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1237.8728,-313.06713 c 2.4798,-9.15136 -0.082,-17.81054 -1.8301,-25.97405", id: "path130-1" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1235.9134,-359.26646 c 0.6031,-4.87395 -0.5374,-11.72144 -1.2355,-15.29139", id: "path131-4" }))));
export default SvgPole;
